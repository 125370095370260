
import { createContext, useMemo, useState } from "react";

import {
  initialParameters,
} from "../common/inputGridColumns.constants";

// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used

export const InputParametersContext = createContext({
  parameterFormData: {},
  setParameterFormData: () => {},
  parameterErrors: {},
  setParameterErrors: () => {},
  initialParameters: {},
});

export const InputParametersProvider = ({ children }) => {

  // parameter data
  // ==============
  const [parameterFormData, setParameterFormData] = useState(initialParameters);
  const [parameterErrors, setParameterErrors] = useState({});


  const value = useMemo(() => ({   
    parameterFormData,
    setParameterFormData,
    parameterErrors,
    setParameterErrors,
    initialParameters,

  }), [parameterFormData, parameterErrors]);

  return (
    <InputParametersContext.Provider value={value}>
      {children}
    </InputParametersContext.Provider>
  );
};
