
import { createContext, useMemo, useState } from "react";

export const InputDataContext = createContext({

  importData: {},
  setImportData: () => {},
  validImportData: {},
  setValidImportData: () => {},

  // accordion state
  expanded: [],
  setExpanded: () => {},
});

export const InputDataProvider = ({ children }) => {

  // accordion handling
  const [expanded, setExpanded] = useState([]);

  // validImportData data
  // ====================
  // validImportData contains the imported data that has been validated
  // it is an object with the following structure:
  // {
  //   dataExists: true/false,
  //   validExists: true/false,
  //   dates: [ { ... } ... ],
  //   people: [ { ... } ... ],
  //   revenues: [ { ... } ... ],
  //   expenses: [ { ... } ... ],
  //   accounts: [ { ... } ... ],
  //   holdings: [ { ... } ... ],  
  // }
  const [importData, setImportData] = useState({});
  
  // import data
  // ===========
  // import data contains the data that has been imported from a file
  // it is an object with the following structure:
  // {
  //   data: [ { ... }, { ... }, ... ],
  // }
  const [validImportData, setValidImportData] = useState({validExists: false, dataExists: false});

  const value = useMemo(() => ({   

    importData, 
    setImportData,
    validImportData, 
    setValidImportData,

    // accordion state
    expanded,
    setExpanded,
  }), [ importData, validImportData, expanded]);

  return (
    <InputDataContext.Provider value={value}>
      {children}
    </InputDataContext.Provider>
  );
};
