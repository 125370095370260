import React, { Fragment, useState, useMemo } from 'react';
import { Box, Grid, Switch, FormControlLabel, useTheme } from '@mui/material';
import Typography from "@mui/material/Typography";
import InfoIconDialog from "../components/InfoIconDialog.component";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatValue } from '../styles/tableComponents.styles';

/*
Original: To better understand the purchasing power of the projected future amount, we present both the future value and its equivalent present value, adjusted for expected inflation.
French: Afin de mieux comprendre le pouvoir d'achat du montant futur projeté, nous présentons à la fois la valeur future et sa valeur actualisée équivalente, ajustée en fonction de l'inflation prévue.
*/

import OverviewTable from '../components/overviewTable.component'

// --------------------------------------------------------------------------------------------

export default function OverviewMainResults({displayData}) {
  
  const theme = useTheme();
  const [presentValue, setPresentValue] = useState(false);


  console.log('OverviewMainResults - displayData', displayData )


  const handleSwitchChange = () => {
    setPresentValue(!presentValue);
    console.log('futurePresent:', presentValue ? 'Future' : 'Present');
  };

  const filteredData = useMemo(() => {
    console.log('filteredData', presentValue )
    return displayData.filter(item => 
      presentValue ? item.key.endsWith('-Today') : ! item.key.endsWith('-Today')
    );
  }, [presentValue, displayData]);

  console.log('OverviewMainResults - filteredData', filteredData )

  return (
    <Fragment>      


        <Box sx={{ display: 'flex', alignItems: 'center' }} paddingLeft={2} paddingBottom={2}>
            <Typography variant="h6" sx={{ width: "33%", flexShrink: 0, color: theme.palette.primary.dark }}>
                Résultat de simulation
            </Typography>
            <Box >
                <Typography sx={{ color: theme.palette.success.dark }}>
                    {/* SUCCÈS */}
                </Typography>
            </Box>
        </Box>
        <Box sx={{ display: 'flex',  flexDirection: 'column', alignItems: 'center' }}  paddingLeft={2} paddingBottom={4}>
            <Box >
                <Typography variant="body2" paddingRight={2} style={{ display: 'inline-block' }}>Futur</Typography>
                <FormControlLabel
                control={
                    <Switch
                    checked={presentValue}
                    onChange={handleSwitchChange}
                    name="futurePresentSwitch"
                    size="small" />
                }
                />
                    <Typography 
                        variant="body2" 
                        style={{ display: 'inline-block' }} 
                        marginLeft={-2} 
                        marginRight={-4} 
                        align="left"
                    >
                        Actuel
                    </Typography>
                    <InfoIconDialog heading="Futur / Actuel" >
                        <Typography variant="body">
                            Afin de mieux comprendre le pouvoir d'achat du montant futur projeté, nous présentons à la fois la valeur future et sa valeur actualisée équivalente, ajustée en fonction de l'inflation prévue.
                        </Typography>
                    </InfoIconDialog>      

            </Box>
            {console.log('OverviewMainResults - displayData', displayData )}
            {console.log('OverviewMainResults - filteredData', filteredData )}
            <OverviewTable displayData={filteredData} />
        </Box>
    </Fragment>
  );
}

