import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import { InputDataContext } from "../contexts/inputData.context";
import { InputPeopleContext } from "../contexts/inputPeople.context";
import { InputDatesContext } from "../contexts/inputDates.context";
import { InputRevenueContext } from "../contexts/inputRevenue.context";
import { InputExpenseContext } from "../contexts/inputExpense.context";
import { InputPortfolioContext } from "../contexts/inputPortfolio.context";
import { GridHeading, PageHeading } from "../styles/heading.styles";
import { Link } from 'react-router-dom';

import { ImportDataGrid } from "../components/input/importDataGrid.component";
import ImportButtons from "../components/importButtons.component";
import BadgeTypography from "../components/badgeTypography.component";
import DownloadFile from "../components/downloadFile.component";

import ExcelReaderXLSX from "../components/input/excelReaderXLSX.component";
import {
  validatePeople,
  validateDates,
  validateRevenues,
  validateExpenses,
  validateAccounts,
  validateHoldings,
} from "../utils/inputValidation.utils";

import {
  supportList,
  taxSchemeList,
  adjustPerYearList,
} from "../common/inputGridColumns.constants";

import { importSimpleData, importAccountData } from "../utils/importData.utils";

function Import() {
  const theme = useTheme();

  const [showOnlyErrors, setShowOnlyErrors] = useState(false); // default show all data

  const { importData, setImportData, validImportData, setValidImportData } =
    useContext(InputDataContext);

  const { peopleList, setPeopleList } = useContext(InputPeopleContext);

  const { dateList, setDateList, initialDates } = useContext(InputDatesContext);

    
  const { revenueList, setRevenueList } = useContext(InputRevenueContext);

  const { expenseList, setExpenseList } = useContext(InputExpenseContext);

  const { accounts, setAccounts, holdings, setHoldings } = useContext(
    InputPortfolioContext
  );

  const EXAMPLE_FILE = "example.xlsx";

  const [exampleFileUrl, setExampleFileUrl] = useState("");

  const handleButtonClick = () => {
    console.log("handleButtonClick - Afficher dans le simulateur");
    setExampleFileUrl(process.env.PUBLIC_URL + '/files/' + EXAMPLE_FILE);
    console.log("handleButtonClick - end");
  };

  const resetData = () => {
    console.log("resetData - start");
    console.log("resetData - initialDates : ", initialDates);
    setPeopleList([]);
    setDateList(initialDates);
    setRevenueList([]); 
    setExpenseList([]);
    setAccounts([]);
    setHoldings([]);    
    setImportData({});
    setValidImportData({
      dataExists: false,
      validExists: false
    });
    setExampleFileUrl("");
    console.log("resetData - importData & ValidImportData");
  }

  useEffect(() => {
    console.log("top of useEffect : importData:", importData);
    console.log("top of useEffect : validImportData:", validImportData);
    console.log("top of useEffect : peopleList:", peopleList);
    console.log("top of useEffect : dateList:", dateList);

    // return if no data to display
    if (!importData) {
      console.log("importData is empty");
      return; // if no data, return
    }

    if (validImportData && Object.keys(validImportData).length > 3) {
      console.log("validImportData is not empty");
      return; // if validImportData is not empty, return
    }
    console.log("Continuing in Import() - useEffect()");

    // populate importedDateList with date names from dateList
    const importedDateList = [];
    dateList.forEach((date) => {
      importedDateList.push(date.name);
    });

    // populate importedPeopleList with people names from peopleList
    const importedPeopleList = [];
    peopleList.forEach((person) => {
      importedPeopleList.push(person.name);
    });

    const validatedData = {};
    console.log("importData:", importData);
    // validate the data
    // start with people
    if (importData && importData["people"]) {
      validatedData["people"] = validatePeople(importData["people"]);
      console.log("validPeople:", validatedData["people"]);
      validatedData["people"].forEach((person) => {
        if (person._valid) {
          importedPeopleList.push(person.name);
        }
      });
      console.log("importedPeopleList:", importedPeopleList);
    }

    if (importData && importData["dates"]) {
      validatedData["dates"] = validateDates(importData["dates"]);
      console.log("validDates:", validatedData["dates"]);
      // make list of dates where key _valid is true
      validatedData["dates"].forEach((date) => {
        if (date._valid) {
          importedDateList.push(date.name);
        }
      });
      console.log("importedDateList:", importedDateList);
    }

    if (importData && importData["revenues"]) {
      validatedData["revenues"] = validateRevenues(
        importData["revenues"],
        importedDateList,
        importedPeopleList
      );
      console.log("validRevenues:", validatedData["revenues"]);
    }

    if (importData && importData["expenses"]) {
      validatedData["expenses"] = validateExpenses(
        importData["expenses"],
        importedDateList,
        importedPeopleList
      );
      console.log("validExpenses:", validatedData["expenses"]);
    }

    if (importData && importData["portfolio"]) {
      // divide portfolio into accounts and holdings
      const accounts = [];
      const holdings = [];
      /* group importData["portfolio"] by "name" and "person"
         to give a result of [ {id: 0, name: "PEA", person: "Lars", initialCapital: 1000, taxScheme: "investCSG", earliestWithdrawalDate: "2021-10-01", withdrawalPriority: "1"],
                               {id: 1, ...}, ... ]
      */
      const portfolio = importData["portfolio"];
      const groupedPortfolio = portfolio.reduce((r, a) => {
        const key = `${a.name}#${a.person}`; // use both name and person as the key
        r[key] = [...(r[key] || []), a];
        return r;
      }, {});
      console.log("groupedPortfolio:", groupedPortfolio);
      // iterate over groupedPortfolio to create accounts and holdings
      for (const [key, account] of Object.entries(groupedPortfolio)) {
        // console.log("key:", key);
        // console.log("account:", account);
        // split key into name and person
        const [name, person] = key.split("#");
        const id = account[0].id;

        const newAccount = {
          id: id,
          name: name,
          person: person,
          initialCapital: parseInt(account[0].initialCapital),
          taxScheme: account[0].taxScheme,
          earliestWithdrawalDate: account[0].earliestWithdrawalDate,
          withdrawalPriority: account[0].withdrawalPriority,
        };
        accounts.push(newAccount);
        account.forEach((holding) => {
          const newHolding = {
            accountId: id,
            support: holding.support,
            cashEquiv: holding.cashEquiv,
            currentValue: parseInt(holding.currentValue),
            id: holding.id,
          };
          holdings.push(newHolding);
        });
      } // end for (const [name, account] of Object.entries(groupedPortfolio))

      console.log("accounts:", accounts);

      // sort accounts by name then person
      accounts.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        if (a.person < b.person) return -1;
        if (a.person > b.person) return 1;

        return 0;
      });
      console.log("accounts (sorted):", accounts);

      console.log("holdings:", holdings);
      // sort holdings by support
      holdings.sort((a, b) => {
        if (a.support < b.support) return -1;
        if (a.support > b.support) return 1;

        return 0;
      });
      console.log("holdings (sorted):", holdings);

      // validate accounts
      validatedData["accounts"] = validateAccounts(
        accounts,
        importedDateList,
        importedPeopleList
      );
      console.log("validAccounts:", validatedData["accounts"]);

      // validate accounts
      validatedData["holdings"] = validateHoldings(holdings, supportList);
      console.log("validHoldings:", validatedData["holdings"]);
    }

    console.log("validatedData - before flags:", validatedData);

    // update status flags
    // ===================

    // check if there is at least one element of data (valid or not)
    const dataExists = Object.keys(validatedData).length > 0;
    validatedData.dataExists = dataExists;

    // check if there is at least one element of valid data
    const validExists = Object.values(validatedData).some(
      (sheetData) =>
        Array.isArray(sheetData) && sheetData.some((row) => row._valid === true)
    );
    console.log("validExists:", validExists);
    validatedData.validExists = validExists;

    console.log("validatedData - final:", validatedData);
    setValidImportData(validatedData);

    //}, [importData]);
  }, [importData, dateList]);

  // onClick handler
  // ===============
  const loadValidData = (event) => {
    event.preventDefault();

    console.log("Dans loadValidData validImportData:", validImportData);
    // "people", "dates", "revenues", "expenses"
    importSimpleData(
      validImportData,
      setValidImportData,
      "people",
      peopleList,
      setPeopleList
    );
    importSimpleData(
      validImportData,
      setValidImportData,
      "dates",
      dateList,
      setDateList
    );
    importSimpleData(
      validImportData,
      setValidImportData,
      "revenues",
      revenueList,
      setRevenueList
    );
    importSimpleData(
      validImportData,
      setValidImportData,
      "expenses",
      expenseList,
      setExpenseList
    );
    // importPeople(validImportData, setValidImportData, peopleList, setPeopleList);
    // importDates(validImportData, setValidImportData, dateList, setDateList);
    // importRevenues(validImportData, setValidImportData, revenueList, setRevenueList);
    // importExpenses(validImportData, setValidImportData, expenseList, setExpenseList);

    importAccountData(
      validImportData,
      setValidImportData,
      accounts,
      setAccounts,
      holdings,
      setHoldings
    );
  }; // end loadValidData

  return (
    <div>
      <PageHeading heading="Importer"/>
      <Grid container paddingTop={2} spacing={2} display="flex" alignItems="stretch">
      <Grid item xs={8}>
        <Box paddingLeft={2} paddingTop={0}>
          <Box paddingLeft={0} paddingTop={0}>
            <Typography variant="body2">
            Pour saisir vos données financières manuellement, utilisez la page {" "}
              <Link to="/inputs">Données</Link>
            </Typography>
          </Box>
          <Typography variant="body2" style={{ fontSize: "0.9rem", fontWeight: "bold", paddingTop: '6px' }}>
            Importer à partir d'un fichier (.xls, .xlsx, .odt)
          </Typography>
          <Box paddingLeft={2} paddingTop={0}>
            <BadgeTypography badgeContent={1} color="error" text="Importez votre fichier" />
            <BadgeTypography badgeContent={2} color="error" text="Chargez les données valides" />
            <BadgeTypography badgeContent={3} color="error" text="Lancez la simulation" />
{/* 
            <ul>Chargez les avec le bouton "Charger les données valides", qui apparaîtra</ul>
                <ul>Si vous voulez, vous pouvez faire des modifications avant de lancer la simulation sur la page <Link to="/inputs">Données</Link></ul>
                <ul>Lancez la simulation</ul>
              </ol>
*/}

          </Box>
          <Typography variant="body2" style={{ fontSize: "0.9rem", fontWeight: "bold", paddingTop: '6px' }}>
            Exemple
          </Typography>
          <Typography variant="body2">
            Les détails du fichier se trouvent dans l'exemple. Vous pouvez le télécharger, le modifier avec vos données, puis l'importer.
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DownloadFile fileName={EXAMPLE_FILE} />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleButtonClick}
                style={{ height: '25px', minHeight: '25px', marginLeft: '10px' }}
            >
                Afficher dans le simulateur
            </Button>
        </div>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box paddingLeft={2} paddingTop={0}>
          <ExcelReaderXLSX key={1} url={exampleFileUrl} />
        </Box>
      </Grid>
    </Grid>

        <Divider orientation="horizontal" sx={{ border: '1px solid black', my: 1 }}/>

        <Grid container paddingTop={0} display="flex" alignItems="stretch">
        <Grid item xs={12}>
          { // Only display the import buttons if there is data to import
            validImportData.dataExists && (
              <Box paddingLeft={2} paddingTop={0} >
                <ImportButtons
                validImportData={validImportData}
                showOnlyErrors={showOnlyErrors}
                setShowOnlyErrors={setShowOnlyErrors}
                loadValidData={loadValidData}
                resetData={resetData}
              />
              </Box>
          )}
        </Grid>
      </Grid>
      {validImportData.dataExists &&
        ["parameters", "people", "dates", "revenues", "expenses"].map(
          (sheetName, index) => {
            const sheetData = validImportData[sheetName];
            if (!sheetData) return null; // Skip if sheet is not found
            const filteredSheetData = sheetData.filter(
              (row) => !row._valid || !showOnlyErrors
            );
            if (!filteredSheetData || filteredSheetData.length === 0)
              return null; // Skip if sheet is not found
            return (
              <ImportDataGrid
                sheet={sheetName}
                data={filteredSheetData}
                key={index}
              />
            );
          }
        )}
      {console.log("importData:", importData) }
      {console.log("validImportData:", validImportData) }
      { (validImportData["accounts"] && validImportData["accounts"].length >= 0 && (
          (showOnlyErrors && (
              validImportData["holdings"].some(item => item._valid === false) || 
              validImportData["accounts"].some(item => item._valid === false)
          )) || !showOnlyErrors
        )      
      ) && (
        <GridHeading heading = "Portefeuille" />          
      )}
      {/* TBD : Put this code in function body */ }
      {(() => {
        if (
          !validImportData["accounts"] ||
          validImportData["accounts"].length === 0
        ) {
          // if there are no accounts, do not display anything
          return;
        }
        console.log("validImportData[accounts]:", validImportData["accounts"]);

        // make a list if all accountIds in holdings that have _valid = false
        const accountIdsToDisplay = [];

        if (!showOnlyErrors) {
          // show all accounts & holdings
          validImportData["accounts"].forEach((account) => {
            accountIdsToDisplay.push(account.id);
          });
        } else {
          // show only accounts & holdings that have _valid = false

          validImportData["holdings"].forEach((holding) => {
            if (!holding._valid) {
              accountIdsToDisplay.push(holding.accountId);
            }
          });
          // add to accountIdsToDisplay all accounts that have _valid = false
          validImportData["accounts"].forEach((account) => {
            if (!account._valid) {
              accountIdsToDisplay.push(account.id);
            }
          });
        }

        // remove duplicates
        const uniqueaccountIdsToDisplay = [...new Set(accountIdsToDisplay)];
        console.log("uniqueaccountIdsToDisplay:", uniqueaccountIdsToDisplay);

        return validImportData["accounts"]
          .filter((account) => uniqueaccountIdsToDisplay.includes(account.id))
          .map((account, index) => {
            const sheetData = [];
            sheetData.push(account);
            console.log("Dans JSX sheet(account) data:", sheetData);
            const accountHoldings = validImportData["holdings"].filter(
              (holdings) =>
                holdings.accountId === account.id &&
                (!showOnlyErrors || !holdings._valid)
            );
            console.log("accountHoldings", accountHoldings);
            return (
              <div key={account.id} style={{ paddingLeft: "2em" }}>
                <Paper
                  key={account.id}
                  elevation={3}
                  style={{ padding: "8px", marginBottom: "16px" }}
                >
                  <Typography variant="h6" style={{ fontSize: "0.8rem" }}>
                    {account.name} / {account.person}
                  </Typography>
                  <ImportDataGrid sheet="accounts" data={sheetData} tight />
                  {accountHoldings.length > 0 && (
                    <ImportDataGrid
                      sheet="holdings"
                      data={accountHoldings}
                      key={index}
                      tight
                      indent={1}
                    />
                  )}
                </Paper>
              </div>
            );
          });
      })()}
      { console.log( 'Before Import Buttons - validImportData', validImportData) }
      { console.log( 'Before Import Buttons - importData', importData) }
      
      
      { // Only display the import buttons if data is currently displayed
        validImportData.dataExists && (
          <Grid container paddingTop={0} display="flex" alignItems="stretch">
          <Grid item xs={12}>
                <Box paddingLeft={2} paddingTop={0} >
                  <ImportButtons
                  validImportData={validImportData}
                  showOnlyErrors={showOnlyErrors}
                  setShowOnlyErrors={setShowOnlyErrors}
                  loadValidData={loadValidData}
                  resetData={resetData}
                />
                </Box>
          </Grid>
        </Grid>
        )}
      <div style={{ height: "50px" }} />{" "}
      {/* This adds an additional space at the bottom */}
    </div>
  );
}

export default Import;
