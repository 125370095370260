import { createContext, useContext, useEffect, useMemo, useState } from "react";

// year : is used to synchronise the year on the chart with the table data year
// chart : does not appear to be used
import { InputDatesContext } from "./inputDates.context";

export const RefLineYearsContext = createContext({
  refLineYears: [],
  setRefLineYears: () => {},
});

export const RefLineYearsProvider = ({ children }) => {
  // import personDropDownList from DropDownListProvider
  // ==================================================
  const { dateList } = useContext(InputDatesContext);

  const [refLineYears, setRefLineYears] = useState([]);

  // create list of dates for use in dropdown list
  useEffect(() => {
    console.log("useEffect dateList:", dateList);
    if (dateList.length >= 0) {
      // create reference line years and names
      // a) process the list of years from the dateList
      // b) for each year :
      //    - if the year has the name "now" or "never", ignore it
      //    - if the year has a name, add the year and name to the RefLineYear list
      //    - if the year already exists in RefLineYear list, concatenate the "name" fields of new year and the existing year

      let label = 'A'.charCodeAt(0); // Start from 'A'

      let refLineYears = [...dateList]
        .sort((a, b) => a.date.localeCompare(b.date)) // Sort dateList by date
        .reduce((acc, dateInfo) => {
          if (dateInfo.name !== "now" && dateInfo.name !== "maintenant" && dateInfo.name !== "never" && dateInfo.name !== "jamais") {
            const year = dateInfo.date.substring(0, 4);
            let existingYear = acc.find((savedLine) => savedLine.year === year);
      
            if (existingYear) {
              existingYear.name += ", " + dateInfo.name;
            } else {
              acc.push({ year: year, name: dateInfo.name, label: String.fromCharCode(label) });
              label++; // Increment label for next push
            }
          }
      
          return acc;
        }, []);

      console.log("refLineYears:", refLineYears);
      setRefLineYears(refLineYears);
    }
  }, [dateList]);

  const value = useMemo(
    () => ({
      refLineYears,
      setRefLineYears,
    }),
    [refLineYears]
  );

  return (
    <RefLineYearsContext.Provider value={value}>
      {children}
    </RefLineYearsContext.Provider>
  );
};
