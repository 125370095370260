import React from "react";
import { Grid, Link, Box } from "@mui/material";
import ResultHeading from "../components/resultHeading.component";

function Podcasts() {
  return (
    <div>
    <Grid container spacing={1}>
      <ResultHeading 
          title="Liens" 
          text="" 
          textBefore="Fiscalité"
          navigateBefore="/tax-summary"
          textNext=""
          navigateNext=""
        />
    </Grid>

    <h1>Podcasts</h1>
      <h3>Many Happy Returns</h3>
      <Box paddingLeft={2} paddingTop={0}>
        <Box>
          <Link href="https://many-happy-returns.captivate.fm/episode/how-to-live-off-your-investments-in-retirement" target="_blank">
            Episode 74: How To Live Off Your Investments In Retirement
          </Link>
        </Box>
      </Box>

      <h3>Rational Reminder</h3>
      <Box paddingLeft={2} paddingTop={0}>
    
        <Box>
          <Link href="https://rationalreminder.ca/podcast/284" target="_blank">
            Episode 284: Prof. Scott Cederburg: Challenging the Status Quo on Lifecycle Asset Allocation
          </Link>
          </Box>
          <Box>
          <Link href="https://rationalreminder.ca/podcast/281" target="_blank">
            Episode 281: Lifecycle Asset Allocation, and Retiring Successfully
            with Justin King
          </Link>
          <Box style={{ marginLeft: '20px' }}>
          <Link href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4590406" target="_blank">
            (The academic paper advising a 50/50 portfolio throught retirement)
          </Link>
          </Box>
          </Box>
        <Box>
          <Link href="https://rationalreminder.ca/podcast/268" target="_blank">
            Episode 268: Itzhak Ben-David: ETFs, Investor Behavior, and Hedge
            Fund Fees
          </Link>
        </Box>
        <Box>
          <Link href="https://rationalreminder.ca/podcast/260" target="_blank">
            Episode 260: Prof. James Choi: Practical Finance
          </Link>
        </Box>
        <Box>
          <Link href="https://rationalreminder.ca/podcast/155" target="_blank">
            Episode 155: Don Ezra: Planning for Life After Full Time Work
          </Link>
        </Box>
      </Box>

      
      <h3>Ben Felix</h3>
      <Box paddingLeft={2} paddingTop={0}>
        <Box>
          <Link href="https://www.youtube.com/watch?v=MOjS2zuQMdo" target="_blank">
            2024-04-11: The Most Important Lessons in Investing
          </Link>
        </Box>
      </Box>


      <h3>The Long View</h3>
      <Box paddingLeft={2} paddingTop={0}>
        <Box>
          <Link href="https://www.morningstar.com/podcasts/the-long-view/63dc1cd1-5ba4-466e-8efe-c72b86c469f0" target="_blank">
            2023-11-14: John Rekenthaler and Amy Arnott: What’s a Safe
            Retirement Spending Rate Today?
          </Link>
        </Box>
      </Box>

      <h1>Livres</h1>
      <h3>Behavioural Investing</h3>
      <Box paddingLeft={2} paddingTop={0}>
        <Box>
          <Link href="https://www.amazon.com/Little-Book-Behavioral-Investing-worst/dp/0470686022" target="_blank">
          The Little Book of Behavioral Investing
          </Link>
        </Box>
      </Box>
      
      <h1>Liens utiles</h1>
      <h3>Calculateur de longévité</h3>
      <Box paddingLeft={2} paddingTop={0}>
        <Box>
          <Link href="https://www.longevityillustrator.org/" target="_blank">
            Longevity Illustrator : Longevity proababilities based on American
            Academy of Actuaries
          </Link>
        </Box>
      </Box>

      <h3>Stratégies de rachat</h3>
      <Box paddingLeft={2} paddingTop={0}>
        <Box>
          <Link href="https://www.forbes.com/sites/robertberger/2023/10/01/5-alternatives-to-the-4-retirement-withdrawal-rule/" target="_blank">
            5 Alternatives To The 4% Retirement Withdrawal Rule
          </Link>
        </Box>
        <Box>
          <Link href="https://www.bogleheads.org/wiki/Variable_percentage_withdrawal" target="_blank">
            Bogleheads : Variable percentage withdrawal
          </Link>
        </Box>
        <Box>
          <Link href="https://ofdollarsanddata.com/safe-withdrawal-rate/" target="_blank">
            Of Dollars And Data : What is the Safe Withdrawal Rate in Retirement?
          </Link>
        </Box>
        <Box>
          <Link href="https://retirementace.co.uk/2023/01/best-of-2022-dynamic-withdrawal-strategies-and-4-rule" target="_blank">
            Retirement Ace : Best Of 2022 – Dynamic Withdrawal Strategies And 4% Rule
          </Link>
        </Box>
        
        <Box>
          <Link href="https://www.youtube.com/watch?v=1FwgCRIS0Wg&list=TLPQMjMwMTIwMjRjU7z6DdyL3Q&index=5" target="_blank">
          Ben Felix : The 2.7% Rule for Retirement Spending
          </Link>
        </Box>

        <Box>
          <Link href="https://portfoliocharts.com/charts/withdrawal-rates/" target="_blank">
          Portfolio Charts : Withdrawal Rates
          </Link>
        </Box>

        
      </Box>

      <h3>Indicateurs</h3>
      <Box paddingLeft={2} paddingTop={0} target="_blank">
        <Box>
          <Link href="https://edition.cnn.com/markets/fear-and-greed">
            CNN Frear and Greed Index
          </Link>
        </Box>
      </Box>
      <Box paddingTop={2} />
    </div>
  );
}

export default Podcasts;
