import React, {useContext} from "react";
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import { InputParametersContext } from "../../contexts/inputParameters.context";
import { useTraceUpdate } from '../../utils/useTraceUpdate.utils';

// <<<<<<<<< HERE >>>>>>>>
// Replace the following with the actual accounts and owners
const accountTypes = [
  "PEA - Lars",
  "PEA - Ju",
  "AssVie Bourso - Lars",
  "PER -Ju",
  "",
];



function InputParameters() {
  const debug = false;
  const _parent = "InputParameters";
  useTraceUpdate({_parent: _parent});

  const {
    parameterFormData,
    setParameterFormData,
    parameterErrors,
    setParameterErrors,
  } = useContext(InputParametersContext);

  // form level handlers
  // ===================
  const handleInputChange = (e) => {
    // update parameterFormData with new value
    const { name, value } = e.target;
    setParameterFormData({ ...parameterFormData, [name]: value });
  };

  if (parameterFormData === undefined) {
    debug && console.log("InputParameters - returning empty: ");
    return;
  }

  // -------------------------

  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      paddingLeft={2}
      paddingBottom={4}
    >
{/*      
      <FormControl fullWidth>
        <InputLabel shrink htmlFor="simulation-mode">
          Simulation Mode
        </InputLabel>
        <Select
          id="simulation-mode"
          name="simulationMode"
          value={parameterFormData.simulationMode}
          onChange={handleInputChange}
          label="Simulation Mode"
        >
          <MenuItem value="fixedGain">Fixed Gain</MenuItem>
          <MenuItem value="montecarlo">Monte Carlo</MenuItem>
          <MenuItem value="historical">Historical</MenuItem>
        </Select>
      </FormControl>
  
      <TextField
        fullWidth
        type="number"
        label="User Confidence Level (%)"
        name="userConfLevel"
        value={parameterFormData.userConfLevel}
        onChange={handleInputChange}
        error={!!parameterErrors.userConfLevel}
        helperText={parameterErrors.userConfLevel}
        inputProps={{
          min: 50,
          max: 98,
        }}
      />


      <TextField
        fullWidth
        type="number"
        label="Investment Percent"
        name="investPercent"
        value={parameterFormData.investPercent}
        onChange={handleInputChange}
        error={!!parameterErrors.investPercent}
        helperText={parameterErrors.investPercent}
        inputProps={{
          min: 0,
          max: 100,
        }}
      />
*/}
      <FormControl fullWidth>
        <InputLabel htmlFor="invest-account">Compte d'investissement</InputLabel>
        <Select
          id="invest-account"
          name="investAccount"
          value={parameterFormData.investAccount}
          onChange={handleInputChange}
        >
          {accountTypes.map((accountType) => (
            <MenuItem key={accountType} value={accountType}>
              {accountType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default InputParameters;
