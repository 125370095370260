import React, { Fragment, useState, useContext, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import ResultHeading from "../components/resultHeading.component";

import OverviewEmergencyFunds from "../components/overviewEmergencyFunds.component";
import OverviewMainResults from "../components/overviewMainResults.component";
import OverviewSuggestions from "../components/overviewSuggestions.component";
import OverviewWithdrawalRates from "../components/overviewWithdrawalRates.component";
import NoSimulationData from '../components/noSimulationData.component';

import { SimulationDataContext } from "../contexts/simulationData.context";
import { PageHeading } from "../styles/heading.styles";

import OverviewButtons from "../components/overviewButtons.component";

// taxation
//  - tax optimisation
//  - tax bracket optimisation
//  - percentage paid in tax per year

/*
    resultSummary : [{
      "basicExpense": 3752782.0,
      "totalWithLuxury": 5843146.0,
      "netIncome": 3769111.0,
      "yearResultBasic": 16329.0,
      "yearResultWithLuxury": -2074035.0,
      "amountInvested": 143549.0,
      "brutWithdrawn": 2574741.0,
      "avgPercentWithdrawnFirst10y": 2.72,
      "maxPercentBrutWithdrawn": 6.12,
      "yearMaxPercentBrutWithdrawn": 2024.0,
      "finalPortfolioValueBrut": 7911783.0,
      "finalPortfolioValueNet": 6353990,
      "simCount": 0,
      "spendingAdjustmentThisCalc": 0
    }]
*/

const displayData = [
  {
    key: "finalPortfolioValueBrut-Future",
    displayText: "Valeur finale du portefeuille (brute)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    key: "finalPortfolioValueNet-Future",
    displayText: "Valeur finale du portefeuille (après impots)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    // present value 
    key: "finalPortfolioValueBrut-Today",
    displayText: "Valeur finale du portefeuille (brute)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    // present value 
    key: "finalPortfolioValueNet-Today",
    displayText: "Valeur finale du portefeuille (après impots)",
    format: "thousandSeparator",
    panel: "mainResults",
  },
  {
    key: "avgPercentWithdrawnFirst10y",
    displayText: "Taux de retrait moyen pendant les 10 premières années",
    format: "percent",
    panel: "withdrawalRates",
  },
  {
    key: "maxPercentBrutWithdrawn",
    displayText: "Taux de retrait maximum",
    format: "percent",
    panel: "withdrawalRates",
  },
  {
    key: "yearMaxPercentBrutWithdrawn",
    displayText: "Année du taux de retrait maximum",
    format: "none",
    panel: "withdrawalRates",
  },
];

export default function Overview() {
  const theme = useTheme();
  const [expanded, setExpanded] = useState([]);

  const { simulationData } = useContext(SimulationDataContext);

  console.log("OverviewHeader - simulationData", simulationData);

  // populate overviewData with relevant values from simulationData
  const overviewData = useMemo(() => {
    const data = [];

    // return if simulationData has not yet been populated
    const numKeys = Object.keys(simulationData).length;
    if (numKeys === 0) {
      return data;
    }

    const resultSummary = simulationData["resultSummary"];

    console.log("OverviewHeader - resultSummary", resultSummary);
    // update displayData with the values from simulationData
    // where the key in simulationData exists as a key in displayData
    displayData.forEach((row) => {
      if (resultSummary[row.key]) {
        data.push({
          ...row,
          value: resultSummary[row.key],
        });
      }
    });
    return data;
  }, [simulationData]);

  console.log("OverviewHeader - overviewData", overviewData);

  const handleCollapseAll = () => {
    setExpanded([]);
  };

  const handleExpandAll = () => {
    setExpanded(["withdrawalRates", "emergencyFunds", "suggestions"]);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((p) => p !== panel));
    }
  };



  return (
    <Fragment>
        <ResultHeading 
          title="Résumé" 
          text=""
          textBefore=""
          navigateBefore=""
          textNext="Trésorie"
          navigateNext="/cashflow"
          />

      {/* Overview Main Results */}
      {console.log("OverviewHeader - overviewData", overviewData)}
      {overviewData && overviewData.length > 0 ? (
        <>
          <OverviewMainResults
            displayData={overviewData.filter((row) => row.panel === "mainResults")}
          />

          <OverviewButtons
            handleCollapseAll={handleCollapseAll}
            handleExpandAll={handleExpandAll}
            expanded={expanded}
            theme={theme}
          />


          <OverviewWithdrawalRates
            expanded={expanded.includes("withdrawalRates")}
            onChange={handleChange("withdrawalRates")}
            displayData={overviewData}
            panel="withdrawalRates"
          />

          {/* Overview Emergency funds */}
{/*

          <OverviewEmergencyFunds
            expanded={expanded.includes("emergencyFunds")}
            onChange={handleChange("emergencyFunds")}
            displayData={overviewData}
            panel="emergencyFunds"
          />
 */}

          {/* Overview Suggestions */}

{/*
          <OverviewSuggestions
            expanded={expanded.includes("suggestions")}
            onChange={handleChange("suggestions")}
          />
 */}
        </>
        ) : (
          <NoSimulationData />
        )
      }


    </Fragment>
  );
}
