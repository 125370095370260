import React, { useEffect, useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import * as XLSX from "xlsx";

import validateData from "../../utils/inputValidation.utils";
import { InputDataContext } from "../../contexts/inputData.context";
import { importValidationSchemas } from "../../common/inputValidation.constants";

function preProcessData(inputArray, sheetName) {
  // Define the state variables for the data and the errors
  console.log("preProcessData (", sheetName, ") inputArray:", inputArray);

  // for revenues and expenses
  if (sheetName === "revenues" || sheetName === "expenses") {
    inputArray.forEach((row, index) => {
      if (row.repeatPeriod !== "" && row.repeatPeriod !== undefined) {
        console.log("preProcessData (", sheetName, ") row(", index, "):", row);
        console.log(
          "preProcessData (",
          sheetName,
          ") row.repeatPeriod (",
          row.repeatPeriod,
          ") type:",
          typeof row.repeatPeriod
        );
        row.repeatPeriod = row.repeatPeriod.toLowerCase();
        switch (row.repeatPeriod) {
          case "m":
            row.repeatPeriod = "monthly";
            break;
          case "y":
            row.repeatPeriod = "yearly";
            break;
          case "o":
            row.repeatPeriod = "oneTime";
            break;
          default:
            row.repeatPeriod = "monthly";
            break;
        }
      }
      // convert frequency to number (set to 1 if empty )
      // console.log("preProcessData (", sheetName, ") before : row.frequency (", row.frequency,") type:", typeof row.repeatPeriod);
      row.repeatFreq =
        row.repeatFreq === "" || row.repeatFreq === undefined
          ? 1
          : parseInt(row.repeatFreq);
      // console.log("preProcessData (", sheetName, ") after : row.repeatFreq (", row.repeatFreq,")");

      // convert amount to number
      row.amount = parseFloat(row.amount);
    });
  }

  if (sheetName === "portfolio") {
    console.log("preProcessData (", sheetName, ") inputArray:", inputArray);
    inputArray.forEach((row, index) => {
      console.log("preProcessData (", sheetName, ") row(", index, "):", row);
      // convert amount to number or to 0 if empty
      row.initialCapital =
        row.initialCapital === "" || row.initialCapital === undefined
          ? 0
          : parseFloat(row.initialCapital);
      row.currentValue =
        row.currentValue === "" || row.currentValue === undefined
          ? 0
          : parseFloat(row.currentValue);
      row.plusValue =
        row.plusValue === "" || row.plusValue === undefined
          ? 0
          : parseFloat(row.plusValue);
      row.initialCapital =
        row.initialCapital === "" || row.initialCapital === undefined
          ? 0
          : parseFloat(row.initialCapital);
      row.withdrawalPriority =
        row.withdrawalPriority === "" || row.withdrawalPriority === undefined
          ? 0
          : parseInt(row.withdrawalPriority);

      row.cashEquiv = row.cashEquiv == true ? true : false;
      // if any of the following fields are empty, copy from previous row
      // 'name', 'person', 'taxScheme', 'earliestWithdrawalDate', 'withdrawalPriority'
      if (row.name === "" || row.name === undefined) {
        row.name = inputArray[index - 1].name;
      }
      if (row.person === "" || row.person === undefined) {
        row.person = inputArray[index - 1].person;
      }
      if (row.taxScheme === "" || row.taxScheme === undefined) {
        row.taxScheme = inputArray[index - 1].taxScheme;
      }
      /*
      if (row.earliestWithdrawalDate === "" || row.earliestWithdrawalDate === undefined) {
        row.earliestWithdrawalDate = inputArray[index-1].earliestWithdrawalDate;
      }
      if (row.withdrawalPriority === "" || row.withdrawalPriority === undefined) {
        row.withdrawalPriority = inputArray[index-1].withdrawalPriority;
      }
      */
    });
    console.log(
      "preProcessData (",
      sheetName,
      ") inputArray (after):",
      inputArray
    );
  }
}

const ExcelReaderXLSX = ({ url }) => {
  const { setImportData } = useContext(InputDataContext);

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  console.log("ExcelReaderXLSX url:", url);

  // Function to parse Excel date serial number
  function parseExcelDate(serial) {
    const utcDays = Math.floor(serial - 25569);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const result = new Date(utcDays * millisecondsPerDay);
    //    return result;
    return result.toISOString().substring(0, 10);
  }

  // Define the dropzone handler
  const onDrop = useCallback((acceptedFiles) => {
    console.log("onDrop called with files:", acceptedFiles);
    setIsDraggingOver(false);

    // Reset the state variables
    let ignoredSheets = [];
    let excelImportData = {};

    // Read the first file (assuming only one file is dropped)
    const file = acceptedFiles[0];
    console.log("file:", file);
    const reader = new FileReader();
    reader.onload = (event) => {
      // Parse the file data as binary string
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      // Get the worksheet names and store them in an array
      const sheetNames = workbook.SheetNames;
      console.log("sheetNames:", sheetNames);
      console.log("importSheetNames:", importValidationSchemas);

      // Loop through each worksheet name
      for (let i = 0; i < sheetNames.length; i++) {
        // Get the current worksheet name
        const sheetName = sheetNames[i];
        console.log("sheetName:", sheetName);
        // continue if sheetName is not a key in importSheetNames
        if (!importValidationSchemas.hasOwnProperty(sheetName)) {
          ignoredSheets.push(sheetName);
          console.log("sheetName:", sheetName, "not in importSheetNames");
          continue;
        }

        // Convert the worksheet data to JSON
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          defval: "", // to handle empty cells
        });
        console.log("sheetData (", sheetName, ") raw: ", sheetData);

        let columnNamesSet = false;
        let columnNames = {};
        const importSheet = [];

        // if sheetData is empty, skip it
        if (sheetData.length === 0) {
          console.log("sheetData (", sheetName, ") is empty");
          continue;
        }

        // loop through all keys in first row and set columnNames
        Object.keys(sheetData[0]).forEach((key) => {
          columnNames[key] = key;
        });

        console.log("columnNames:", columnNames);

        // Now, iterate over the sheetData and parse date fields
        for (let i = 0; i < sheetData.length; i++) {
          const rowNum = i + 2; // row numbers in Excel sheet start at 2 (1 is header row);
          const row = sheetData[i];
          console.log("(", sheetName, ") row(", rowNum, "):", row);

          // delete empty rows and rows that start with "#"
          if (Object.keys(row).length === 0) {
            // console.log("ignoring row - empty", rowNum, ":", row);
            continue;
          }
          const firstCell = Object.values(row)[0];
          if (typeof firstCell === "string" && firstCell.startsWith("#")) {
            console.log("ignoring row - starts with comment", rowNum, ":", row);
            continue;
          }

          // line is valid
          // set column names based on vamues in first non-ignored row. If i == 0 (first row) then columnNames has already been set
          if (!columnNamesSet) {
            columnNamesSet = true;
            if (rowNum > 2) {
              // console.log("setting column names rowNum(", rowNum,") cols (", row, ")");
              columnNames = { ...row };
              console.log("column name conversion(", columnNames, ")");
              continue;
            }
          }

          // console.log("row:", row);
          const processedRow = {};

          // Parse date fields (adjust field names as needed)
          for (const [key, value] of Object.entries(row)) {
            const realColumnName = columnNames[key];
            console.log(
              "row(",
              rowNum,
              ") col(",
              key,
              ") realColumnName(",
              realColumnName,
              ") value(",
              value,
              ") type:",
              typeof value,
              ")"
            );

            if (
              realColumnName.endsWith("Date") ||
              realColumnName.endsWith("date")
            ) {
              /*                            
                | Condition         | Field     | Raw data    | Processed   | isNaN(raw value) | type of (raw value) |
                | Valid text Date   | startDate | sellCityZen | sellCityZen | TRUE             | string              |
                | Valid actual date | endDate   | 45077       | 2023-05-31  | FALSE            | number              |
                | Null field        | endDate   | <empty>     | 1899-12-30  | FALSE            | string              | 
                => conclusion : if type is string, then use value as is, otherwise convert to date
              */
              const finalValue =
                typeof value === "string" ? value : parseExcelDate(value);

              // console.log("DATE before(", value, ") after(", finalValue, ") isNaN(value):", isNaN(value),") type:", typeof value, ")");
              processedRow[realColumnName] = finalValue;
            } else {
              processedRow[realColumnName] = value;
            }
            processedRow["id"] = rowNum;
            processedRow["key"] = rowNum;
          }
          const cashEquiv = processedRow["cashEquiv"];
          console.log(
            "processedRow(",
            rowNum,
            ") processedRow(",
            processedRow,
            ")"
          );
          // importSheet = [...importSheet, {...processedRow}];
          importSheet.push({ ...processedRow });
          // importSheet = importSheet.concat({...processedRow});
          // importSheet.push({...processedRow});
          // console.log("importSheet(",  rowNum,") importSheet(",importSheet[importSheet.length-1],")");
          // importSheet[importSheet.length-1]["cashEquiv_2"] = cashEquiv;
          console.log(
            "importSheet final(",
            rowNum,
            ") importSheet(",
            importSheet[importSheet.length - 1],
            ")"
          );

          /*
          // delete all elements in processedRow
          for (const [key, value] of Object.entries(processedRow)) {
            processedRow[key] = "";
          }
          console.log("processedRow deleted(",  rowNum,") processedRow(",processedRow,")");
*/
        }
        console.log("sheetData (", sheetName, ") converted: ", importSheet);

        preProcessData(importSheet, sheetName);
        // const validated = validateData(importSheet, sheetName );
        // console.log("sheetData (", sheetName, ") validated: ", validated);
        // excelImportData[sheetName] = validated;
        excelImportData[sheetName] = importSheet;
      }
      excelImportData["_ignoredSheets"] = ignoredSheets;
      console.log("excelImportData:", excelImportData);
      setImportData(excelImportData);
    };

    // file is actually read here
    console.log("load from file:", file);
    reader.readAsBinaryString(file);
  }, []);

  useEffect(() => {
    if (url) {
      console.log("load from URL:", url);
      fetch(url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const file = new File([arrayBuffer], "example.xlsx");
          onDrop([file]);
        });
    }
  }, [url, onDrop]);

  // Define the dropzone properties
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (event) => {
    // event.preventDefault();
    setIsDraggingOver(false);
    // handle dropped file
  };

  const dropZoneStyle = {
    textAlign: "center",
    border: "2px dashed #ccc",
    borderRadius: "5px",
    padding: "25px",
    backgroundColor: isDraggingOver ? "#eee" : "#fff",
    //    cursor: "pointer",
  };

  return (
    <div className="App">
      <div
        {...getRootProps()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        style={dropZoneStyle}
      >
        <input {...getInputProps()} />
        <Badge
          badgeContent={1}
          color="error"
          sx={{ marginRight: "10px", marginTop: "-20px" }}
        />
        <Typography
          variant="body2"
          style={{ display: "inline-block", paddingLeft: "1em" }}
        >
          Faites glisser et déposez un fichier Excel ici, ou cliquez pour
          sélectionner un fichier
        </Typography>
      </div>
    </div>
  );
};

export default ExcelReaderXLSX;
