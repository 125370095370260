import React, { useState, useMemo, useCallback, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Button, Container, Grid, Typography, useTheme } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {
  AmountInput,
  DateInput,
  TextInput,
} from "../../styles/tableComponents.styles.jsx";
import { DateWithPresetsInput } from "../../styles/dateWithPresetsInput.styles.jsx";
import { DropDownPresetsInput } from "../../styles/dropDownPresetsInput.styles.jsx";

import { repeatPeriodList } from "../../common/inputValidation.constants";

import { useTraceUpdate } from "../../utils/useTraceUpdate.utils";

function InputWithGrid(props) {
  const theme = useTheme(); // Get the theme object
  const debug = false;
  const { _parent }=  props;
  const _parentAndChild = _parent + "/InputWithGrid";
  useTraceUpdate({...props, _parent: _parentAndChild});

  const {
    inputName,
    inputCols,
    formData,
    setFormData,
    dataList,
    setDataList,
    errors,
    setErrors,
    initialFormData,
    predefinedDates,
    personDropDownList,
    adjustPerYearList,
    taxSchemeList,
  } = props;

  debug && console.log("InputWithGrid (", inputName, ")- top of function dataList: ", dataList);

  /*
  const {
    formData,
    setFormData,
    dataList,
    setDataList,
    errors,
    setErrors,
    initialFormData,
    predefinedDates,
  } = useContext(InputDataContext);
*/
  // const [formData, setFormData] = useState(initialFormData);
  // const [dataList, setDataList] = useState([]);
  // const [errors, setErrors] = useState({});
  const [editId, setEditId] = useState(null);

  const validateDates = () => {
    if (new Date(formData.startDate) > new Date(formData.endDate)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        startDate: "La date de début doit être antérieure à la date de fin",
        endDate: "La date de début doit être antérieure à la date de fin",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      date: "",
    }));
    return true;
  };

  const actionColumn = {
    field: "actions",
    headerName: "Actions",
    flex: 1.2,
    headerClassName: "header-class",
    headerAlign: "center",
    renderCell: (params) => (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {handleEdit(params.row);}}
        >
          Modifier
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleDelete(params.id);
          }}
        >
          Effacer
        </Button>
      </>
    ),
  };

  // add action column to inputCols
  /*
  const columns = useMemo(() => {
    console.log("InputWithGrid (",inputName,") - columns: ", inputCols);
    return [...inputCols, actionColumn];
  }, []);
*/
  useEffect(() => {
    console.log("InputWithGrid - (", inputName, ") dataList updated: ", dataList );
  }, [dataList]);

  // form level handlers
  // ==================
  const handleInputChange = (e) => {
    // update formData with new value
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = useCallback(
    (event) => {
      // display error message if dates are not valid
      event.preventDefault();
      if (!validateDates()) {
        return;
      }
      if (editId !== null) {
        // Modify existing data
        const newDataList = [...dataList];
        // find the element in newDataList which has id == editId and replace it with formData
        const index = newDataList.findIndex((item) => item.id === editId);
        newDataList[index] = { id: editId, ...formData };
        setDataList(newDataList);

        setEditId(null);
      } else {
        // Add new data
        // find max id in dataList
        const maxId =
          dataList.length === 0
            ? 0
            : Math.max(...dataList.map((item) => item.id));
        setDataList([...dataList, { id: maxId + 1, ...formData }]);
      }
      setFormData(initialFormData);
    },
    [formData, editId, dataList]
  );

  // data grid handlers
  // ==================
  const handleEdit = useCallback(
    (rowToEdit) => {
      // load formData with data to edit, referenced by id
      // const dataToEdit = dataList.find((item) => item.id === rowToEdit);
      // console.log("InputWithGrid - (",inputName,") handleEdit: dataToEdit ", dataToEdit);
      setFormData(rowToEdit);
      // setEditId(idToEdit);
      setEditId(rowToEdit.id);
    },
    [setFormData]
  );

  const handleDelete = useCallback(
    (idToDelete) => {
      const newDataList = dataList.filter((item) => item.id !== idToDelete);
      setDataList([...newDataList]);
    },
    [dataList, setDataList]
  );

  // data entry handlers
  // ===================
  const handlePeriodKeyDown = useCallback(
    (event) => {
      if (formData.period === undefined) {
        return;
      }

      const key = event.key.toLowerCase();
      const items = repeatPeriodList;
      const match = items.find((item) => item.toLowerCase().startsWith(key));

      if (match) {
        setFormData({ ...formData, period: match });
      }
    },
    [formData, setFormData]
  );

  // to solve error: TypeError: inputCols is not iterable
  if (!inputCols || !Symbol.iterator in Object(inputCols)) {
    return;
  }

  const columns = [...inputCols, actionColumn];

  debug && console.log("InputWithGrid (", inputName, ")- dataList: ", dataList);
  debug && console.log("InputWithGrid (", inputName, ")- formData: ", formData);

  if (formData === undefined) {
    debug && console.log("InputWithGrid (", inputName, ") - returning empty: ");
    return;
  }

  return (
    <>
      <Container
        sx={{
          "& .header-class": {
            backgroundColor: theme.palette.primary.main, // Use the primary color from the theme
            color: theme.palette.primary.contrastText, // Use the contrasting text color
          },
        }}
      >
        <Grid container spacing={2} paddingTop={2}>
          {/* Common fields
              ============= */}

          {
            // display grid if element exists in columns array with field === "label"
            columns.find((element) => element.field === "label") ? (
              <Grid item xs={3}>
                <TextInput
                  label="Label"
                  name="label"
                  value={formData.label}
                  onChange={handleInputChange}
                />
              </Grid>
            ) : null
          }

          {/* People form
              ========== */}
          {
            // display grid if element exists in columns array with field === "label"}
            columns.find((element) => element.field === "name") ? (
              <Grid item xs={3}>
                <TextInput
                  label="Nom"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "date"}
            columns.find((element) => element.field === "birthDate") ? (
              /* type="date" input field has a built-in placeholder text that cannot be overwritten */

              <Grid item xs={2.2}>
                <DateInput
                  label="Date de naissance"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleInputChange}
                  errors={errors}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "label"}
            columns.find((element) => element.field === "age") ? (
              <Grid item xs={1.2}>
                <AmountInput
                  label="Age"
                  name="age"
                  value={formData.age}
                  onChange={handleInputChange}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "label"}
            columns.find((element) => element.field === "lifespanAge") ? (
              <Grid item xs={2}>
                <AmountInput
                  label="Espérance de vie"
                  name="lifespanAge"
                  value={formData.lifespanAge}
                  onChange={handleInputChange}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "date"}
            columns.find((element) => element.field === "retireDate") ? (
              /* type="date" input field has a built-in placeholder text that cannot be overwritten */

              <Grid item xs={2.2}>
                <DateInput
                  label="Retire Date"
                  name="retireDate"
                  value={formData.retireDate}
                  onChange={handleInputChange}
                  errors={errors}
                />
              </Grid>
            ) : null
          }

          {/* Dates form
              ========== */}

          {
            // display grid if element exists in columns array with field === "date"}
            columns.find((element) => element.field === "date") ? (
              /* type="date" input field has a built-in placeholder text that cannot be overwritten */

              <Grid item xs={2.2}>
                <DateInput
                  label="Date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  errors={errors}
                />
              </Grid>
            ) : null
          }
          {
            // display grid if element exists in columns array with field === "comment"}
            columns.find((element) => element.field === "comment") ? (
              <Grid item xs={3}>
                <TextInput
                  label="Commentaire"
                  name="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                />
              </Grid>
            ) : null
          }

          {/* Expense & revenue form
              ====================== */}
          {
            // display grid if element exists in columns array with field === "label"}
            columns.find((element) => element.field === "amount") ? (
              <Grid item xs={1.2}>
                <AmountInput
                  label="Montant"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                />
              </Grid>
            ) : null
          }
          {
            // display grid if element exists in columns array with field === "period"}
            columns.find((element) => element.field === "repeatPeriod") ? (
              <Grid item xs={1.5} onKeyDown={handlePeriodKeyDown}>
                <DropDownPresetsInput
                  label="Période"
                  name="repeatPeriod"
                  value={formData.repeatPeriod}
                  onChange={handleInputChange}
                  dropDownList={repeatPeriodList}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "frequency"}
            columns.find((element) => element.field === "repeatFreq") ? (
              <Grid item xs={1}>
                <AmountInput
                  label="Fréquence"
                  name="repeatFreq"
                  value={formData.repeatFreq}
                  onChange={handleInputChange}
                  disabled={formData.period === "oneTime"}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "startDate"}
            columns.find((element) => element.field === "startDate") ? (
              // type="date" input field has a built-in placeholder text that cannot be overwritten

              <Grid item xs={2.2}>
                <DateWithPresetsInput
                  label="Début"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  error={errors}
                  predefinedDates={predefinedDates}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "endDate"}
            columns.find((element) => element.field === "endDate") ? (
              // type="date" input field has a built-in placeholder text that cannot be overwritten
              <Grid item xs={2.2}>
                <DateWithPresetsInput
                  label="Fin"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  error={errors}
                  predefinedDates={predefinedDates}
                  disabled={formData.period === "oneTime"}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "person"}
            columns.find((element) => element.field === "person") ? (
              <Grid item xs={1.5}>
                <DropDownPresetsInput
                  label="Personne"
                  name="person"
                  value={formData.person}
                  onChange={handleInputChange}
                  dropDownList={personDropDownList}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "adjustPerYear"}
            columns.find((element) => element.field === "adjustPerYear") ? (
              <Grid item xs={1.8}>
                <DropDownPresetsInput
                  label="Ajustement annuel"
                  name="adjustPerYear"
                  value={formData.adjustPerYear}
                  onChange={handleInputChange}
                  dropDownList={adjustPerYearList}
                />
              </Grid>
            ) : null
          }

          {
            // display grid if element exists in columns array with field === "taxScheme"}
            columns.find((element) => element.field === "taxScheme") ? (
              <Grid item xs={1.5}>
                <DropDownPresetsInput
                  label="Régime fiscal"
                  name="taxScheme"
                  value={formData.taxScheme}
                  onChange={handleInputChange}
                  dropDownList={taxSchemeList}
                />
              </Grid>
            ) : null
          }

          {/* Adjust spacing to put Add/modify button at end of current row */}
          {inputName === "People" ? <Grid item xs={1} /> : null}
          {inputName === "Dates" ? <Grid item xs={0} /> : null}
          {inputName === "Revenus" ? <Grid item xs={5.2} /> : null}
          {inputName === "Dépenses" ? <Grid item xs={5.2} /> : null}

          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ height: "2em" }}
            >
              {editId !== null ? "Modifier" : "Ajouter"}
            </Button>
          </Grid>
        </Grid>

        {dataList.length === 0 ? null : (
          <>
            {/* calculate the height of the grid based on the number of rows */}
            <div style={ {paddingTop:"10px", height: 30 * (dataList.length + 2) -10 }}>
              <DataGrid
                rows={dataList}
                columns={columns}
                scrollbarSize={10}
                hideFooterRowCount
                disableColumnResize
                disableColumnMenu
                columnHeaderHeight={30}
                rowHeight={30}
                hideFooter={true}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default InputWithGrid;
