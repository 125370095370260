

function maxId(list) {
  return list.length === 0 ? 0 : Math.max(...list.map((list) => list.id));
}

export function importSimpleData( validImportData, setValidImportData, type, list, setList) {
  // load valid data 
  if (validImportData && validImportData[type]) {
    const validData = validImportData[type];
    const newList = [...list];
    console.log("importSimpleData (", type, ") existing:", list, " to import:", validData);
    validData.forEach((row, index) => {
      // only insert new entry if person is valid, not already imported and does not already exist
      if (row._valid && !row._imported) {
        if ( list.some((p) => p.name.toLowerCase() === row.name.toLowerCase()) ) {
          validData[index]._errMessages = type + " '" + row.name + "' already exists";
        } else {
          // remove fields from row that should not be added to list
          const { _valid, _imported, _errMessages, _index, ...rest } = row;
          newList.push({
            // add all fields from row except _valid & _imported
            ...rest,
            id: maxId(newList) + 1,
            // retireDate: person.retireDate
          });
          validData[index]._imported = true;
          validData[index]._errMessages = "IMPORTÉ";
        }
      }
    });
    setValidImportData(validImportData, { [type] : validData });
    console.log("importSimpleData (", type, ") adding:", newList);
    setList(newList);
  }
}

export function importAccountData( validImportData, setValidImportData, accounts, setAccounts, holdings, setHoldings) {
  // load valid people
  if (validImportData && validImportData["accounts"]) {
    const validAccounts = validImportData["accounts"];
    const newAccounts = (accounts && accounts.length > 0) ? [...accounts] : [];
    console.log("importAccountData (accounts) existing:", accounts, " to import:", validAccounts);
    validAccounts
      .sort((a, b) => {
        return a.name.localeCompare(b.name) || a.person.localeCompare(b.person);
      })
      .forEach((account, index) => {
      // only insert new entry if person is valid, not already imported and does not already exist
      if (account._valid && !account._imported) {
        if (  accounts &&
              accounts.length > 0 &&
              accounts.some((p) => p.name.toLowerCase() === account.name.toLowerCase()) ) {
          validAccounts[index]._errMessages = "account '" + account.name + "' already exists";
        } else {
          // remove fields from account that should not be added to accounts
          const { _valid, _imported, _errMessages, _index, ...rest } = account;
          newAccounts.push({
            // add all fields from row except _valid & _imported
            ...rest,
//            id: maxId(newAccounts) + 1,
            // retireDate: person.retireDate
          });
          validAccounts[index]._imported = true;
          validAccounts[index]._errMessages = "IMPORTÉ";

        }
      }
    });
    setValidImportData(validImportData, { accounts: validAccounts });
    console.log("importAccountData (accounts) adding:", newAccounts);
    setAccounts(newAccounts);

    // load holdings for the accounts that were imported

    // get ids of accounts that were imported
    const importedAccountIds = validAccounts.filter((account) => account._imported).map((account) => account.id);
    console.log("importAccountData (holdings) importedAccountIds:", importedAccountIds);

    // get holdings for the imported accounts
    const validHoldings = validImportData["holdings"].filter((holding) => importedAccountIds.includes(holding.accountId));
    
    const newHoldings = [...holdings];
    console.log("importAccountData (holdings) existing:", holdings, " to import:", validHoldings);
    validHoldings.forEach((holding, index) => {
      // only insert new entry if person is valid, not already imported and does not already exist
      if (holding._valid && !holding._imported) {
        // remove fields from holding that should not be added to holdings
        const { _valid, _imported, _errMessages, _index, ...rest } = holding;
        newHoldings.push({
          // add all fields from row except _valid & _imported
          ...rest,
//          id: maxId(newHoldings) + 1,
          // retireDate: person.retireDate
        });
        validHoldings[index]._imported = true;
        validHoldings[index]._errMessages = "IMPORTÉ";

      }
    });
    setValidImportData(validImportData, { holdings : validHoldings });
    console.log("importAccountData (holdings) adding:", newHoldings);
    setHoldings(newHoldings);

  }
}
