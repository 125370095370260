import React, { useContext, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  useTheme,
} from "@mui/material";
import Badge from '@mui/material/Badge';
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import { InputParametersContext } from "../contexts/inputParameters.context";
import { InputPeopleContext } from "../contexts/inputPeople.context";
import { InputDatesContext } from "../contexts/inputDates.context";
import { InputRevenueContext } from "../contexts/inputRevenue.context";
import { InputExpenseContext } from "../contexts/inputExpense.context";
import { InputPortfolioContext } from "../contexts/inputPortfolio.context";

import { SimulationDataContext } from "../contexts/simulationData.context";

import { preProcessData } from "../utils/preProcessing.utils";

// create a button called "simulation". When the button is pressed:
// a) send all the input data tha has been collected to the backend server
// b) display a spinner while waiting for the response
// c) receive the simulation data from the backend server
// d) load the data into the simulationData context and open the cashflow page

const Simulate = ({ buttonBadgeText}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const { parameterFormData } = useContext(InputParametersContext);

  const { peopleList } = useContext(InputPeopleContext);
  const { dateList } = useContext(InputDatesContext);

  const { revenueList } = useContext(InputRevenueContext);
  const { expenseList } = useContext(InputExpenseContext);
  const { accounts, holdings } = useContext(InputPortfolioContext);

  const { setSimulationData } = useContext(SimulationDataContext);

  const startSimulation = () => {
    setIsLoading(true);
    console.log("startSimulation - parameterFormData:", parameterFormData);

    /* convert parameterFormData from format :
    {
      "simulationMode": "fixedGain",
      "userConfLevel": 80,
      "investPercent": 80,
      "investAccount": ""
    }
     to format :
     [
      {"name": "simulationMode", "value": "fixedGain"},
      {"name": "userConfLevel", "value": 80},
      {"name": "investPercent", "value": 80},
      {"name": "investAccount", "value": ""}
     ]
     */
    const parameters = Object.entries(parameterFormData).map(
      ([name, value]) => ({ name, value })
    );
  

    const data = {
      people: peopleList,
      dates: dateList,
      revenues: revenueList,
      expenses: expenseList,
      accounts: accounts,
      holdings: holdings,
      parameters: parameters,
    };

    console.log("sending simulation data : ", data);

    const test_URL = "http://localhost:8000/backend/simulator/";
    const prod_URL = "https://www.firecracker.how/backend/simulator/";

    // if this is running on localhost, use the test_URL, otherwise use the prod_URL
    const URL = window.location.href.includes("localhost") ? test_URL : prod_URL;
    console.log("backend URL", URL);

    // const URL = "http://firecracker.eu.pythonanywhere.com/backend/simulator/";

    axios
      .post(URL, data)
      .then((response) => {
        console.log("response from server : ", response.data);

        // The response data is in response.data

        const {
          people,
          resultSummary,
          cashflow,
          revenue,
          expense,
          ptfSummary,
          assetAlloc,
          safeYears,
          taxSummary,
        } = response.data;

        console.log("resultSummary - recvd:", resultSummary);

        // convert cashflow from json to dict
        const resultSummaryDict = JSON.parse(resultSummary);
        const cashflowDict = JSON.parse(cashflow);
        const revenueDict = JSON.parse(revenue);
        const expenseDict = JSON.parse(expense);
        const peopleDict = JSON.parse(people);
        const ptfSummaryDict = JSON.parse(ptfSummary);
        const assetAllocDict = JSON.parse(assetAlloc);
        const taxSummaryDict = JSON.parse(taxSummary);
        const safeYearsDict = JSON.parse(safeYears);

        console.log("maxYear", resultSummaryDict["maxYear"]);
        console.log("resultSummaryDict", resultSummaryDict);
/*
        console.log("cashflow", cashflowDict);
        console.log("revenue", revenueDict);
        console.log("expense", expenseDict);
        console.log("people", peopleDict);
        console.log("ptfSummary", ptfSummaryDict);
        console.log("assetAlloc", assetAllocDict);
        console.log("resultSummary", resultSummaryDict);
        console.log("taxDetail", taxSummaryDict);
*/
        const receivedData = {
          assetAlloc: assetAllocDict,
          cashflow: cashflowDict,
          expense: expenseDict,
          people: peopleDict,
          ptfSummary: ptfSummaryDict,
          resultSummary: resultSummaryDict,
          revenue: revenueDict,
          safeYears: safeYearsDict,
          taxSummary: taxSummaryDict,
        };


        console.log("receivedData['people']", receivedData['people']);
        preProcessData(receivedData);
        console.log("receivedData - after preProcessing", receivedData);

        setSimulationData(receivedData);

        setIsLoading(false); // Hide spinner
        navigate("/overview");
      })
      .catch((error) => {
        console.error("There was an error!", error);
        let errorData = error.response && error.response.data ? JSON.stringify(error.response.data, null, 2) : "No additional error data available";
        setErrorMessage(
          `Il y avait une erreur: ${error.message} \nInformations complémentaires: ${errorData}`
        );



        setOpen(true);
        setIsLoading(false); // Hide spinner
      });
  };

  return (
    <Grid item xs={4}>
      <div style={{ position: "relative" }}>
        <Button
          onClick={startSimulation}
          variant="contained"
          color="primary"
          sx={{ height: "2em", width: "16em" }}
          disabled={
            !(
              (revenueList && revenueList.length) ||
              (expenseList && expenseList.length) ||
              (holdings && holdings.length)
            )
          } // disable button if there is no valid data
          title={
            !(
              (revenueList && revenueList.length) ||
              (expenseList && expenseList.length) ||
              (holdings && holdings.length)
            )
              ? "Aucune donnée valide à charger"
              : ""
          } // tooltip if there is no valid data to load
          
          startIcon={buttonBadgeText !== "" ? <Badge badgeContent={buttonBadgeText} color="error" sx={{ marginRight: '5px' }}/> : null}

        >
          Lancer la simulation
        </Button>

        {isLoading && (
          <CircularProgress
            size={68} // Adjust the size as needed
            color="primary"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -34, // Half of the spinner size
              marginLeft: -34, // Half of the spinner size
            }}
          />
        )}
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
        <DialogContent>
          {errorMessage.split("\n").map((line, index) => (
            <DialogContentText
              key={index}
              id={`alert-dialog-description-${index}`}
            >
              {line}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Simulate;
