// fixed dropdown lists
// ====================
export const adjustPerYearList = ["inflation", "revenueAdj", "noChange", "indexLoyer"];

export const supportList = [
  "Emerging Markets",
  "Real Estate",
  "Cash",
  "Bonds",
  "Europe",
  "French",
  "International",
  "REIT",
  "Structured",
  "Japan",
  "Technology",
];

/*
export const taxSchemeList = [
    "AssVie",
    "ExemptTax",
    "FlatTax",
    "IncomeCSG",
    "incomeTaxBands_1p",
    "incomeTaxBands_2p",
    "InvestCSG",
    "KapitalPension",
    "PERdeduitCapital_p2",
    "PERdeduitRente_p2",
    "PERnondeduitCapital_p2",
    "PERnondeduitRente70_p2",
    "RealEstateIncome",
  ];
*/
export const taxSchemeList = [
  "Assurance_vie",
  "Sans_impot",
  "CTO",
  "PEA",
  "PER_capital_deduit_p1",
  "PER_capital_deduit_p2",
  "PER_capital_nondeduit_p1",
  "PER_capital_nondeduit_p2",
  "IR_salaire_1p",
  "IR_salaire_2p",
  "IR_sans_abattement_1p",
  "IR_sans_abattement_2p",
  "Sans_impot",
  "Revenu_immobilier_1p",
  "Revenu_immobilier_2p"
];
  


export const dropDownLists = {
  adjustPerYearList: adjustPerYearList,
  supportList: supportList,
  taxSchemeList: taxSchemeList,
};

// Input DataGrid  Columns

// person data
// ============
export const peopleCols = [
  {
    field: "name",
    headerName: "Nom",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "birthDate",
    headerName: "Naissance",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "lifespanAge",
    headerName: "Durée de vie",
    flex: 0.8,
    headerClassName: "header-class",
  },
  /*
  {
    field: "retireDate",
    headerName: "Retire Date",
    flex: 0.8,
    headerClassName: "header-class",
  },
  */
];

export const initialPeopleForm = {
  name: "",
  birthDate: "",
  lifespanAge: "",
//  retireDate: "",
};

// date data
// ============
export const dateCols = [
  {
    field: "name",
    headerName: "Nom",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "date",
    headerName: "Date",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "comment",
    headerName: "Commentaire",
    flex: 1.5,
    headerClassName: "header-class",
  },
];

export const initialDates = [
  {
    id: 0,
    name: "maintenant",
    date: (new Date()).toISOString().substring(0, 10),
    comment: "Début des simulations",
  },
  {
    id: 1,
    name: "jamais",
    date: '2200-01-01',
    comment: "Fin du temps",
  },
]


export const initialDateForm = {
  name: "",
  date: "",
  comment: "",
};

// revenue data
// ============
export const revenueCols = [
  {
    field: "name",
    headerName: "Nom",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "amount",
    headerName: "Montant",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "person",
    headerName: "Personne",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "repeatPeriod",
    headerName: "Période",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "repeatFreq",
    headerName: "Freq.",
    flex: 0.4,
    headerClassName: "header-class",
  },
  {
    field: "startDate",
    headerName: "Début",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "endDate",
    headerName: "Fin",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "adjustPerYear",
    headerName: "Ajust. annuel",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "taxScheme",
    headerName: "Régime fiscal",
    flex: 0.8,
    headerClassName: "header-class",
  },
];

export const expenseCols = [
  {
    field: "name",
    headerName: "Nom",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "amount",
    headerName: "Montant",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "person",
    headerName: "Personne",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "luxury",
    headerName: "Discrétionnaire",
    flex: 0.3,
    headerClassName: "header-class",
  },
  {
    field: "repeatPeriod",
    headerName: "Période",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "repeatFreq",
    headerName: "Freq.",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "startDate",
    headerName: "Début",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "endDate",
    headerName: "Fin",
    flex: 0.8,
    headerClassName: "header-class",
  },
  {
    field: "adjustPerYear",
    headerName: "Ajust. annuel",
    flex: 0.8,
    headerClassName: "header-class",
  },
];


export const initialRevenueForm = {
  name: "",
  amount: "",
  repeatPeriod: "monthly",
  repeatFreq: "1",
  startDate: "Now",
  endDate: "",
  person: "",
  adjustPerYear: "inflation",
  taxScheme: "Sans_impot",
};

// expense data
// =============
export const initialExpenseForm = {
  name: "",
  amount: "",
  repeatPeriod: "monthly",
  repeatFreq: "1",
  startDate: "Now",
  endDate: "",
  luxury: false,
  person: "",
  adjustPerYear: "inflation",
};

// portfolio data
// ==============
export const accountCols = [
  {
    field: "name",
    headerName: "Nom",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "person",
    headerName: "Personne",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "initialCapital",
    headerName: "Capital initial",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "taxScheme",
    headerName: "Régime fiscal",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "earliestWithdrawalDate",
    headerName: "Pas rachat avant",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "withdrawalPriority",
    headerName: "Priorité",
    flex: 1,
    headerClassName: "header-class",
  }
];

export const initialAccounts = [
  {
    id: "",
    name: "",
    person: "",
    initialCapital: "",
    taxScheme: "",
    earliestWithdrawalDate: "",
    withdrawalPriority: "",
    currentValue: 0,
  },
];

export const holdingCols = [
  {
    field: "support",
    headerName: "Valeur",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "currentValue",
    headerName: "Valeur actuelle",
    flex: 1,
    headerClassName: "header-class",
  },
  {
    field: "cashEquiv",
    headerName: "Équivalent liquide",
    flex: 1,
    headerClassName: "header-class",
  },
];


export const initialHoldings = [
  { id: "", accountId: "", support: "", currentValue: "", cashEquiv: "" },
];

// parameters
// ==========
export const initialParameters = {
  simulationMode: "fixedGain",
  userConfLevel: 80,
  investPercent: 80,
  investAccount: "",
};

export const ImportDataGridColumns ={
    people: peopleCols,
    dates: dateCols,
    revenues: revenueCols,
    expenses: expenseCols,
    portfolio: [],
    accounts: accountCols,
    holdings: holdingCols,
    parameters: [],
    tax_schemes: [],
};
